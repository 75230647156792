@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-26ThIt.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-26ThIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-25Th.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-25Th.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-66MdIt.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-66MdIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-45Lt.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-45Lt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDispPro';
  src: url('../fonts/NHaasGroteskDSPro-46LtIt.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSPro-46LtIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

$font-family: 'NeueHaasGroteskDispPro', 'Helvetica Neue', 'Arial', sans-serif;
