@import './fonts.scss';
@import './colors.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

.pre {
  white-space: pre-line;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  font-family: $font-family;

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: $c-light;
  }

  .mapboxgl-popup-content {
    background-color: $c-light;
    padding: 10px;

    h2, h3 {
      color: $c-dark;
      line-height: 1.1;
    }

    h2 {
      margin: 0;

      & + p {
        margin-top: 10px;
      }
    }

    h3 {
      margin: 10px 0 5px 0;
    }

    p {
      margin: 0;
      line-height: 1.1;
      color: $c-dark;
      font-size: 13px;
    }
  }

  .huma-marker {
    display: block;
    width: 60px;
    height: 160px;
    background-image: url("../img/marker-huma.png");
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  #huma-popup {
    text-align: center;
    padding: 10px;

    img {
      width: 200px;
      height: auto;
      display: inline-block;
      margin-bottom: 20px;
    }

    a {
      font-size: 16px;
      font-weight: bold;
      color: $c-light;
      background-color: $c-dark;
      border: 2px solid $c-dark;
      display: inline-block;
      padding: 5px 12px;
      border-radius: 20px;
      text-decoration: none;

      &:hover {
        color: $c-dark;
        background-color: $c-light;
      }
    }
  }
}

#topleft_layer {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;

  img {
    display: block;
    width: 150px;
    height: auto;
  }

  h3 {
    margin: 0;
    color: $cdea-blue;
  }
}

#bottomleft_layer {
}

#topright_layer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;

  .filters {
    display: flex;
    flex-direction: row;

    p {
      color: $c-dark;
      font-weight: bold;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      margin: auto 10px;
    }

    button {
      outline: none;
      line-height: 1.1;
      background-color: $c-light;
      color: $c-dark;
      border: 3px solid $c-dark;
      display: inline-block;
      padding: 10px 16px 8px 16px;

      &:hover, &.active {
        cursor: pointer;
        background-color: $c-dark;
        color: $c-light;
      }

      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-right-width: 0;
      }

      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-left-width: 0;
      }
    }
  }
}
