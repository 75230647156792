$c-light: #FFFFFF;
$c-dark: #002b43;
$c-red: #FF0207;
$c-green: #23AA66;
$c-purple: #B17DBD;
$c-lightpurple: #FCE8EF;
$c-lightblue: #3A78AE;
$c-pink: #DD4679;
$c-yellow: #FEBB02;
$c-orange: #F39100;
$c-darkblue: #195493;
$cdea-blue: #008ED2;
$cdea-green: #BCDB4C;
